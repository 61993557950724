import React, { FunctionComponent } from 'react';
import { LearnerNavStyles } from '../hero-styles';

const DefaultHero: FunctionComponent = () => {
    return (
        <LearnerNavStyles>
            <h2>What&apos;s it like to train with us?</h2>
            <h3>Our learners say it best.</h3>
            <h3>
                <a href={`/learner-stories`} aria-label="Learner stories">
                    See their stories&nbsp;
                    <svg enableBackground="new 0 0 24 24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="20" height="20">
                        <path d="m4 11v2h12l-5.5 5.5 1.42 1.42 7.92-7.92-7.92-7.92-1.42 1.42 5.5 5.5z" fill="#ffffff" />
                    </svg>
                </a>
            </h3>
        </LearnerNavStyles>
    );
};

export default DefaultHero;
