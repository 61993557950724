import { css } from 'styled-components';

export const tableStyles = css`
    table {
        color: #1b2733;
        border-collapse: collapse;
        border-spacing: 0;
        width: 100%;
        font-size: 1.6rem;
        letter-spacing: 0.03rem;
        thead {
            margin: 0;
            padding: 0;
            border: 0;
            vertical-align: baseline;
        }
        tbody {
            display: table-row-group;
        }
        th {
            padding: 0.8rem;
            color: #455060;
            border-color: #dce2ed;
            border-width: 0.1rem;
            border-top: none;
            text-transform: uppercase;
            text-align: left;
            font-weight: normal;
            &:last-child {
                text-align: right;
            }
        }
        td {
            vertical-align: middle;
            padding: 1.6rem 0.8rem;
            border: none;
            border-top: 0.1rem solid #dce2ed;
            text-align: left;
            &:last-child {
                text-align: right;
            }
        }
    }
`;
export default tableStyles;
