import { css } from 'styled-components';

export const textStyles = css`
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: 600;
        color: ${(props) => props.theme.primary};
    }

    h1 {
        font-size: 4rem;
    }

    h2 {
        font-size: 3.2rem;
    }

    h3 {
        font-size: 2.6rem;
    }

    h4 {
        font-size: 2.4rem;
    }

    h5 {
        font-size: 1.6rem;
    }

    h6 {
        font-size: 1.4rem;
    }

    p,
    ul {
        margin: 1rem 0;
        color: ${(props) => props.theme.primary};
    }

    a {
        color: ${(props) => props.theme.blue};
        text-decoration: none;

        &:active,
        &:focus {
            outline: none;
        }
    }
`;

export default textStyles;
