import React, { Fragment } from 'react';
import Hero from './components/home/Hero';
import Competitive from './components/home/Competitive';
import SocialProof from './components/home/SocialProof';
import OurLibrary from './components/home/OurLibrary';
import Trainers from './components/home/Trainers';
import GetStarted from './components/home/GetStarted';

const IndexPage = () => {
    return (
        <Fragment>
            <Hero />
            <Competitive />
            <SocialProof />
            <OurLibrary />
            <Trainers />
            <GetStarted />
        </Fragment>
    );
};

export default IndexPage;
