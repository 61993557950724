import React, { FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { SiteLayoutWrapper } from './site-layout-style';
import { theme } from '../../../styles/theme';
import { GlobalStyle } from '../../../styles/global-styles';

export const SiteLayout: FunctionComponent = ({ children }) => {
    return (
        <ThemeProvider theme={theme}>
            <GlobalStyle />
            <SiteLayoutWrapper>
                <main>{children}</main>
            </SiteLayoutWrapper>
        </ThemeProvider>
    );
};

SiteLayout.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.node]),
};

export default SiteLayout;
