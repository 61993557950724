import { DefaultTheme } from 'styled-components';

export const theme: DefaultTheme = {
    /* colors */
    black: '#000',
    white: '#fff',
    offWhite: '#f4f5f8',
    gold: '#ffbc00',
    blue: '#0F62FA',
    lightBlue: '#008CFF',
    primary: '#1b2733',
    secondary: '#455060',
    secondaryHover: '#343F4D',
    tertiary: '#687588',
    greyBorder: '#dce2ed',
    placeholderGrey: '#dce2ed',
    greyLight: '#eff2f6',
    greyFont: '#778599',
    darkGrey: '#404040',
    mediumGrey: '#ccd4e3',
    ccnaLanderGrey: '#8e8c99',
    greyBlue: '#5c697d',
    trainerVidBG: '#f2f5f9',
    red: '#ff2b56',
    green: '#3fc38f',
    darkGreen: '#257757',
    overlay: 'rgba(27, 39, 51, 0.3)',

    /* Shaddows */
    textShadow: '0 0 0.5em rgba(27, 39, 51, 0.5)',
    boxShadow: '0 0 3px rgba(27, 39, 51, 0.75)',
    greyBoxShadow: '0, 0, 3px 0 rgb(239, 242, 246)',

    /* Font Sizes */
    largeFont: '1.8rem',
    smallFont: '1.4rem',
    standardFont: '1.6rem',

    /* Font Variables */
    fontFamily: '"Source Sans Pro", sans-serif',
    baseSpacing: '1.5rem',
    defaultDistanceToFooter: '2rem',

    /* Font Weights */
    bold: 600,
    regular: 400,
    light: 300,

    /* Media query breakpoint variables */
    xsphone: '320px',
    smphone: '400px',
    phone: '480px',
    phone560: '560px',
    phone590: '590px',
    smtablet: '680px',
    tablet: '768px',
    desktop930: '930px',
    desktop960: '960px',
    desktop990: '990px',
    desktop1000: '1000px',
    desktop1100: '1100px',
    desktop1200: '1200px',
    desktop1300: '1300px',
    desktop1400: '1400px',

    /* Site max width setting */
    siteContentMaxWidth: '1400px',
    siteContentReducedWidth: '1200px'
};
