import React, { FunctionComponent } from 'react';
import { BaseLinkType } from '../../common/BaseLinkType';
import { OurLibraryStyle } from './our-library-styles';
import { vendors } from './vendors';

const OurLibrary: FunctionComponent = () => (
    <OurLibraryStyle>
        <h2>Train and certify on top technologies from popular vendors.</h2>
        <article className="library-vendors">
            {vendors.map(({ name, link, image }) => {
                return (
                    <BaseLinkType key={name} href={`/it-training/${link}`} aria-label={`${name} page`}>
                        {image}
                    </BaseLinkType>
                );
            })}
        </article>
        <article className="library-certificates">
            <BaseLinkType href="/certification-playlist/cisco" aria-label="CCNA">
                CCNA
            </BaseLinkType>
            <BaseLinkType href="/certification-playlist/cisco" aria-label="CCNP">
                CCNP
            </BaseLinkType>
            <BaseLinkType href="/it-training/comptia-network-plus-007" aria-label="Network+">
                Network+
            </BaseLinkType>
            <BaseLinkType href="/it-training/comptia-security-plus-sy0-501" aria-label="Security+">
                Security+
            </BaseLinkType>
            <BaseLinkType href="/certification-playlist/microsoft" aria-label="MCSA">
                MCSA
            </BaseLinkType>
            <BaseLinkType href="/certification-playlist/microsoft" aria-label="MCSE">
                MCSE
            </BaseLinkType>
            <BaseLinkType href="/all-courses" className="full-library" aria-label="Full Library of training">
                See all training
                <svg enableBackground="new 0 0 24 24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="m4 11v2h12l-5.5 5.5 1.42 1.42 7.92-7.92-7.92-7.92-1.42 1.42 5.5 5.5z" fill="#ffffff" />
                </svg>
            </BaseLinkType>
        </article>
    </OurLibraryStyle>
);

export default OurLibrary;
