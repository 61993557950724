import React from 'react';
import PropTypes from 'prop-types';
import { StyledButton, StyledButtonSecondary } from './ButtonStyles';

// eslint-disable-next-line
const LinkAsButton = ({ children, secondary = false, ...attributes }) =>
    secondary ? (
        <StyledButtonSecondary as="a" {...attributes}>
            {children}
        </StyledButtonSecondary>
    ) : (
        <StyledButton as="a" {...attributes}>
            {children}
        </StyledButton>
    );

LinkAsButton.propTypes = {
    loggedin: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.node]),
};

export default LinkAsButton;
