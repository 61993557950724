import styled from 'styled-components';

export const TrainersSection = styled.section`
    width: 100%;
    background-color: ${(props) => props.theme.trainerVidBG};
    padding: 4rem 0 31.5%;
    text-align: center;
    position: relative;
    overflow: hidden;

    h2 {
        position: relative;
        font-weight: 600;
        margin-top: 10rem;
        z-index: 1;

        @media screen and (max-width: ${(props) => props.theme.desktop1100}) {
            margin-top: 4rem;
        }

        @media screen and (max-width: ${(props) => props.theme.phone560}) {
            font-size: 2.6rem;
        }
    }

    a {
        position: relative;
        z-index: 1;
        margin-bottom: 2rem;
    }

    .trainer-video {
        position: absolute;
        top: 3.2rem;
        left: 0;
        width: 100%;
        height: auto;
        max-height: 80rem;

        @media screen and (max-width: ${(props) => props.theme.desktop1100}) {
            position: relative;
        }
    }

    @media screen and (max-width: ${(props) => props.theme.desktop1400}) {
        padding-bottom: 36%;
    }

    @media screen and (max-width: ${(props) => props.theme.desktop1200}) {
        padding-bottom: 30%;
    }

    @media screen and (max-width: ${(props) => props.theme.desktop1100}) {
        top: 0%;
        padding-bottom: 0;
    }

    @media screen and (max-width: ${(props) => props.theme.phone560}) {
        padding-top: 0.1rem;
    }
`;
