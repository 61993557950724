import React, { FunctionComponent } from 'react';
import { BaseLinkType } from '../../common/BaseLinkType';
import { FeatureStyle } from './feature-styles';

interface FeatureProps {
    image: JSX.Element;
    description: JSX.Element;
    imageText: string;
    headerText: string;
    learnMoreLink?: string;
}

const Feature: FunctionComponent<FeatureProps> = props => {
    const { image, imageText, headerText, description, learnMoreLink } = props;

    return (
        <FeatureStyle>
            <div className="fixed-img-height">{image}</div>
            <h3>{headerText}</h3>
            <p>
                {description}
                {learnMoreLink !== '' && (
                    <BaseLinkType href={learnMoreLink || ''} aria-label={`Link to ${imageText} page`}>
                        {' Learn more '}
                        <svg enableBackground="new 0 0 24 24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="m4 11v2h12l-5.5 5.5 1.42 1.42 7.92-7.92-7.92-7.92-1.42 1.42 5.5 5.5z"
                                fill="#0f62fa"
                            />
                        </svg>
                    </BaseLinkType>
                )}
            </p>
        </FeatureStyle>
    );
};

export default Feature;
