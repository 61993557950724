import styled, { css } from 'styled-components';
import { lighten, darken } from 'polished';

export const buttonBase = css`
    font-size: ${(props) => (props.small ? '1.4rem' : props.large ? '1.8rem' : '1.6rem')};
    font-weight: 600;
    border-radius: 3.2rem;
    padding: ${(props) => (props.small ? '0.5rem 1.8rem' : props.large ? '1.3rem 5rem' : '1rem 3rem')};
    background-position: center;
    background-color: ${(props) => props.theme.gold};
    border: 0.1rem solid ${(props) => props.theme.gold};
    color: ${(props) => props.theme.primary};
    font-family: ${(props) => props.theme.fontFamily};
    transition: background 0.6s ease 0s, border-color 0.3s ease 0s, background-color 0.3s ease 0s;
    width: fit-content;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
`;

export const noStylingBase = css`
    background-color: transparent;
    border: none;
    width: fit-content;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
`;

// the '&&' adds specificity levels for components witb completing specificity
// in this case the link colkor for post content and link buttons exist on the same specificty level so the blog is winning
// This forces the generating of extra hash levels to force a higher level of specificity
export const StyledButton = styled.button.attrs((props) => ({
    bgColor: props.noStyling ? 'transparent' : props.loggedin ? props.theme.primary : props.theme.gold,
    bgHoverColor: props.noStyling ? 'transparent' : props.loggedin ? lighten(0.15, props.theme.primary) : lighten(0.15, props.theme.gold)
}))`
    && {
        ${(props) => (props.noStyling ? noStylingBase : buttonBase)}
        background-color: ${(props) => props.bgColor};
        border: ${(props) => props.loggedin && `0.1rem solid ${props.theme.primary}`};
        color: ${(props) => props.loggedin && props.theme.white};
        cursor: pointer;
        outline: none;
        &:hover {
            color: ${(props) => (props.noStyling ? 'inheret' : props.loggedin ? props.theme.white : props.theme.primary)};
            background: ${(props) => props.bgHoverColor} radial-gradient(circle, transparent 1%, ${(props) => props.bgHoverColor} 1%)
                center/15000%;
        }
        &:active {
            background-size: 100%;
            background-color: ${(props) => darken(props.loggedin ? 0.1 : 0.05, props.bgColor)};
            transition: background 0s;
        }
    }
`;

export const StyledButtonSecondary = styled.button`
    && {
        ${(props) => (props.noStyling ? noStylingBase : buttonBase)}
        background-color: ${(props) => props.theme.white};
        border: 0.2rem solid ${(props) => (props.loggedin ? props.theme.primary : props.theme.gold)};
        padding: ${(props) => (props.small ? '0.5rem 1.8rem' : props.large ? '1.3rem 5rem' : '1rem 3rem')};
        color: ${(props) => props.theme.primary};
        cursor: pointer;
        outline: none;
        &:hover {
            background-color: ${(props) => (props.loggedin ? props.theme.mediumGrey : props.theme.transparent)};
        }
    }
`;
