import { aws } from './logos/aws';
import { cisco } from './logos/cisco';
import { linux } from './logos/linux';
import { comptia } from './logos/comptia';
import { microsoft } from './logos/microsoft';
import { vmware } from './logos/vmware';

interface VendorInfo {
    name: string;
    link: string;
    image: JSX.Element;
}

export const vendors: VendorInfo[] = [
    {
        name: 'aws',
        link: 'amazon-web-services-training',
        image: aws
    },
    {
        name: 'cisco',
        link: 'cisco-training',
        image: cisco
    },
    {
        name: 'linux',
        link: 'linux-training',
        image: linux
    },
    {
        name: 'comptia',
        link: 'comptia-training',
        image: comptia
    },
    {
        name: 'microsoft',
        link: 'microsoft-training',
        image: microsoft
    },
    {
        name: 'vmware',
        link: 'vmware-training',
        image: vmware
    }
];
