import React, { FunctionComponent } from 'react';
import { LinkAsButton } from '../../common/Buttons';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { TrainersSection } from './trainers-style';

export const Trainers: FunctionComponent = () => (
    <LazyLoadComponent>
        <TrainersSection>
            <h2>Train with the IT elite.</h2>
            <LinkAsButton href="/trainers" aria-label="Trainers">
                Meet our trainers
            </LinkAsButton>
            <video autoPlay loop muted playsInline width="100%" className="trainer-video">
                Your browser does not support the video tag.
            </video>
        </TrainersSection>
    </LazyLoadComponent>
);

export default Trainers;
