import React, { FunctionComponent } from 'react';
import { GetStartedSection } from './get-started-style';

const GetStartedBlock: FunctionComponent = () => {
    return (
        <GetStartedSection>
            <svg
                className="hotspots spots1"
                width="244px"
                height="244px"
                viewBox="0 0 244 244"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg">
                <defs>
                    <pattern id="getStartedpattern1" x="0" y="0" width=".075" height=".075">
                        <circle cx="1" cy="1" r=".8" fill="#455060" />
                        <circle cx="10" cy="10" r=".8" fill="#455060" />
                    </pattern>
                </defs>
                <g id="CircleDots">
                    <circle fill="url(#getStartedpattern1)" cx="122" cy="122" r="122" />
                </g>
            </svg>
            <svg
                className="hotspots spots2"
                width="244px"
                height="244px"
                viewBox="0 0 244 244"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg">
                <use xlinkHref="#CircleDots" />
            </svg>
            <svg
                className="hotspots spots3"
                width="244px"
                height="244px"
                viewBox="0 0 244 244"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg">
                <use xlinkHref="#CircleDots" />
            </svg>
            <svg
                className="hotspots spots4"
                width="488px"
                height="477px"
                viewBox="0 0 488 477"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg">
                <use xlinkHref="#DotsCircles" />
            </svg>
            <strong>Not sure where to start?</strong>
            <br />
            Call 541-284-5522 or try our.
        </GetStartedSection>
    );
};

export default GetStartedBlock;
