import React from 'react';
import { UserContextProvider } from './context/UserContext';
import SiteLayout from './components/layouts/SiteLayout';
import Home from './Home';

function App() {
    return (
        <UserContextProvider>
            <SiteLayout>
                <Home />
            </SiteLayout>
        </UserContextProvider>
    );
}

export default App;
