import styled from 'styled-components';

export const FeatureStyle = styled.figure`
    text-align: left;
    max-width: 100%;
    margin: unset;

    h3 {
        font-size: 2.6rem;
        font-weight: 600;
        margin: 0;
    }

    p {
        font-size: 1.6rem;
        margin: 0;
    }

    a {
        display: inline-flex;
        align-items: center;
        margin-top: 0;
        margin-left: 0.3rem;
        font-size: 1.6rem;
        position: relative;

        svg {
            position: absolute;
            width: 2rem;
            right: -2rem;
            top: 0.25rem;
        }
    }

    .fixed-img-height {
        height: 4rem;
        width: 4rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-size: contain;

        @media only screen and (max-width: ${(props) => props.theme.phone560}) {
            margin-bottom: 0.5rem;

            img {
                max-height: 2.6rem;
                max-width: 2.6rem;
            }
        }
    }

    @media only screen and (max-width: ${(props) => props.theme.phone}) {
        max-width: 100%;

        h1 {
            font-size: 2.2rem;
            margin-top: 2rem;
        }
    }
`;
