import React, { Fragment, FunctionComponent } from 'react';
import { SocialProofStyle } from './social-proof-styles';
import { vendors } from './vendors';

export const SocialProof: FunctionComponent = () => (
    <SocialProofStyle>
        <h2>Delivering trusted IT training across industries and around the world.</h2>
        <article>
            {vendors.map((vendor, i) => (
                <Fragment key={i}>{vendor}</Fragment>
            ))}
        </article>
    </SocialProofStyle>
);

export default SocialProof;
