import { google } from './logos/google';
import { centuryLink } from './logos/centuryLink';
import { comcast } from './logos/comcast';
import { amazon } from './logos/amazon';
import { facebook } from './logos/facebook';
import { salesforce } from './logos/salesforce';
import { telus } from './logos/telus';
import { verizon } from './logos/verizon';

export const vendors: JSX.Element[] = [google, centuryLink, amazon, comcast, telus, verizon, facebook, salesforce];
