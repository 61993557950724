import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

type PdfButtonProps = {
    hideAtMobile?: boolean;
    target?: string;
    rel?: string;
    href?: string;
};

const PdfButton = (props: PdfButtonProps) => {
    return (
        <PdfButtonContainer {...props}>
            <svg height="17" viewBox="0 0 14 17" width="14" xmlns="http://www.w3.org/2000/svg">
                <path d="m19 9h-4v-6h-6v6h-4l7 7zm-14 9v2h14v-2z" fill="#1b2733" fillRule="evenodd" transform="translate(-5 -3)" />
            </svg>
            <span>PDF</span>
        </PdfButtonContainer>
    );
};

const PdfButtonContainer = styled.a<PdfButtonProps>`
    background-color: transparent;
    color: ${(props) => props.theme.primary};
    border: 0.1rem solid ${(props) => props.theme.primary};
    border-radius: 3.2rem;
    padding: 0.5rem 1.8rem;
    font-size: ${(props) => props.theme.smallFont};
    font-weight: 600;
    line-height: 1.5;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.6s, border-color 0.3s, background-color 0.3s;
    max-height: 3.3rem;
    &:hover {
        cursor: pointer;
        background: ${(props) => props.theme.mediumGrey} radial-gradient(circle, transparent 1%, ${(props) => props.theme.mediumGrey} 1%) center/15000%;
        text-decoration: none;
    }
    svg {
        width: 1.3rem;
        margin-right: 0.5rem;
    }
    @media screen and (${(props) => props.theme.tablet}) {
        display: ${(props) => (props.hideAtMobile ? 'none' : 'flex')};
    }
`;

PdfButton.propTypes = {
    hideAtMobile: PropTypes.bool
};

export default PdfButton;
