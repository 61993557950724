import heroDesktop from './images/hero-desktop.jpg';
import heroTablet from './images/hero-tablet.jpg';
import heroMobile from './images/hero-mobile.jpg';
import heroMobile2x from './images/hero-mobile@2x.jpg';

import chuDesktop from './images/chu-desktop.jpg';
import chuTablet from './images/chu-tablet.jpg';
import chuMobile from './images/chu-mobile.jpg';
import chuMobile2x from './images/chu-mobile@2x.jpg';
import donnaDesktop from './images/donna-desktop.jpg';
import donnaTablet from './images/donna-tablet.jpg';
import donnaMobile from './images/donna-mobile.jpg';
import donnaMobile2x from './images/donna-mobile@2x.jpg';

export interface LearnerHeroInfo {
    id: string;
    firstName: string;
    lastName: string;
    title: string;
    link: string;
    linkText: string;
    imageMobile: string;
    imageDesktop: string;
    imageTablet: string;
    imageMobile2x: string;
}

export const emptyLearner: LearnerHeroInfo = {
    id: 'empty',
    firstName: '',
    lastName: '',
    title: '',
    link: '',
    linkText: '',
    imageMobile: '',
    imageMobile2x: '',
    imageTablet: '',
    imageDesktop: ''
};

// eslint-disable-next-line
export const learnerHeroes: LearnerHeroInfo[] = [
    {
        id: 'v01',
        firstName: 'Donna',
        lastName: 'Davis',
        title: 'IT Associate | Hoboken, NJ',
        link: 'donna-davis',
        linkText: 'See her story',
        imageMobile: donnaMobile,
        imageMobile2x: donnaMobile2x,
        imageTablet: donnaTablet,
        imageDesktop: donnaDesktop
    },
    {
        id: 'v02',
        firstName: 'Chu',
        lastName: 'Kong',
        title: 'IT Director | New York, NY',
        link: 'chu-kong',
        linkText: 'See his story',
        imageMobile: chuMobile,
        imageMobile2x: chuMobile2x,
        imageTablet: chuTablet,
        imageDesktop: chuDesktop
    }
];

export const defaultHero: LearnerHeroInfo = {
    id: '',
    firstName: '',
    lastName: '',
    title: '',
    link: '',
    linkText: '',
    imageMobile: heroMobile,
    imageMobile2x: heroMobile2x,
    imageTablet: heroTablet,
    imageDesktop: heroDesktop
};
