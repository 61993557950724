import styled from 'styled-components';

export const SocialProofStyle = styled.section`
    text-align: center;
    margin: 0 auto;
    background-color: ${(props) => props.theme.offWhite};
    padding: 3rem 0;

    h2 {
        font-size: 1.8rem;
        font-weight: 400;
        color: ${(props) => props.theme.greyFont};

        @media only screen and (max-width: ${(props) => props.theme.phone560}) {
            max-width: 70%;
            margin: 0 auto;
        }
    }

    article {
        max-width: 75rem;
        height: auto;
        margin: 3rem auto;
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: center;

        svg {
            margin: 2.5rem 2.5rem;
            flex: 1 auto;
            min-width: 11rem;
        }
    }

    @media only screen and (max-width: ${(props) => props.theme.desktop1200}) {
        article {
            width: auto;
        }
    }

    @media only screen and (max-width: ${(props) => props.theme.phone590}) {
        article svg {
            min-width: 16.5rem;
        }
    }

    @media only screen and (max-width: ${(props) => props.theme.phone560}) {
        margin: 0 auto;

        h1 {
            max-width: 40rem;
            margin: 0 auto;
        }
    }

    @media only screen and (max-width: ${(props) => props.theme.smphone}) {
        article svg {
            flex: 1 0 100%;
            min-width: 20rem;
        }
    }
`;
