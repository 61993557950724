import React, { FunctionComponent } from 'react';
import { LinkAsButton } from '../../common/Buttons';
import DefaultHero from './DefaultHero';
import { defaultHero } from './learner-heros';
import { TrainingCTA, ImageCaption, LearnerSection, BottomCurve } from './hero-styles';

const Hero: FunctionComponent = () => {
    return (
        <LearnerSection learner={defaultHero}>
            <TrainingCTA>
                <h1>
                    <div className="lead-copy">Expert IT training — when and where you need it.</div>
                </h1>
                <p>
                    For 20+ years, IT pros and teams have trusted CBT Nuggets for in-demand technology training
                    available anytime, anywhere.
                </p>
                <LinkAsButton href="/signup" aria-label="Learner Stories">
                    Start a free week
                </LinkAsButton>
                <a href="/business" aria-label="Explore options for IT teams" className="teams">
                    Explore options for IT teams{' '}
                    <svg enableBackground="new 0 0 24 24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="m4 11v2h12l-5.5 5.5 1.42 1.42 7.92-7.92-7.92-7.92-1.42 1.42 5.5 5.5z" fill="#ffffff" />
                    </svg>
                </a>
            </TrainingCTA>
            <ImageCaption>
                <DefaultHero />
            </ImageCaption>
            <BottomCurve />
        </LearnerSection>
    );
};

export default React.memo(Hero);
