import React from 'react';

interface FeatureInfo {
    image: JSX.Element;
    description: JSX.Element;
    imageText: string;
    headerText: string;
    learnMoreLink?: string;
}

export const features: FeatureInfo[] = [
    {
        image: (
            <svg width="30px" height="20px" viewBox="0 0 30 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g id="VideosPage" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="VideosDesktop" transform="translate(-309.000000, -1220.000000)" fill="#FFBC00">
                        <g id="video" transform="translate(304.000000, 1210.000000)">
                            <path
                                d="M28.3333333,17.5 L28.3333333,11.6666667 C28.3333333,10.75 27.5833333,10 26.6666667,10 L6.66666667,10 C5.75,10 5,10.75 5,11.6666667 L5,28.3333333 C5,29.25 5.75,30 6.66666667,30 L26.6666667,30 C27.5833333,30 28.3333333,29.25 28.3333333,28.3333333 L28.3333333,22.5 L35,29.1666667 L35,10.8333333 L28.3333333,17.5 Z"
                                id="PathVids"
                            />
                        </g>
                    </g>
                </g>
            </svg>
        ),
        imageText: 'videos',
        headerText: 'Videos',
        description: <span>Watch on-demand training from industry experts with focused, engaging videos available anytime.</span>,
        learnMoreLink: '/all-courses'
    },
    {
        image: (
            <svg width="30px" height="34px" viewBox="0 0 30 34" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g transform="translate(-309.000000, -1363.000000)" fill="#FFBC00">
                        <g transform="translate(304.000000, 1360.000000)">
                            <g>
                                <g>
                                    <path
                                        d="M34.9999947,27.5 C35.001572,28.1152157 34.6612022,28.6803581 34.1166655,28.9666667 L20.9499994,36.3666667 C20.379824,36.7666667 19.6201749,36.7666667 19.0499995,36.3666667 L5.88333344,28.9666667 C5.33879408,28.680361 4.99842313,28.1152167 4.99999469,27.5 L4.99999469,12.5 C4.99842313,11.8847833 5.33879408,11.319639 5.88333344,11.0333333 L19.0499995,3.63333333 C19.6201749,3.23333333 20.379824,3.23333333 20.9499994,3.63333333 L34.1166655,11.0333333 C34.6612048,11.319639 35.0015758,11.8847833 34.9999947,12.5 L34.9999947,27.5 L34.9999947,27.5 Z M20.0000004,6.91666683 L10.066667,12.5 L20.0000004,18.0833332 L29.9333338,12.5 L20.0000004,6.91666683 L20.0000004,6.91666683 Z M8.33333333,26.5166666 L18.3333333,32.1499999 L18.3333333,20.9666667 L8.33333333,15.3500001 L8.33333333,26.5166666 L8.33333333,26.5166666 Z M31.6666671,26.5166666 L31.6666671,15.3500001 L21.6666671,20.9666667 L21.6666671,32.1499999 L31.6666671,26.5166666 Z"
                                        id="ShapeVirtual"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        ),
        imageText: 'virtual labs',
        headerText: 'Virtual labs',
        description: (
            <span>No need to invest in expensive equipment. Our virtual labs run the software and hardware you need to simulate real-world situations.</span>
        ),
        learnMoreLink: '/features/virtual-labs'
    },
    {
        image: (
            <svg width="30px" height="33px" viewBox="0 0 30 33" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g transform="translate(-661.000000, -1362.000000)" fill="#FFBC00">
                        <g transform="translate(304.000000, 1360.000000)">
                            <g transform="translate(352.000000, 0.000000)">
                                <g>
                                    <path d="M12.8000001,22.3999997 L15.2000001,19.9999998 L18.4,23.1999997 L25.6,15.9999998 L27.9999999,18.3999998 L18.4,27.9999998 L12.8000001,22.3999997 Z M11.9999997,11.9999999 L27.9999992,11.9999999 L27.9999992,8.79999995 L31.1999991,8.79999995 L31.1999991,31.1999993 L8.79999975,31.1999993 L8.79999975,8.79999995 L11.9999997,8.79999995 L11.9999997,11.9999999 L11.9999997,11.9999999 Z M19.9999995,5.60000002 C20.8831995,5.60000002 21.5999995,6.31680001 21.5999995,7.19999999 C21.5999995,8.08319997 20.8831995,8.79999995 19.9999995,8.79999995 C19.1167995,8.79999995 18.3999995,8.08319997 18.3999995,7.19999999 C18.3999995,6.31680001 19.1167995,5.60000002 19.9999995,5.60000002 L19.9999995,5.60000002 Z M31.2000008,5.60000022 L24.5120006,5.60000022 C23.8400005,3.74400015 22.0800005,2.4000001 20.0000004,2.4000001 C17.9200003,2.4000001 16.1600002,3.74400015 15.4880002,5.60000022 L8.79999995,5.60000022 C7.03359988,5.60000022 5.59999983,7.03360028 5.59999983,8.80000035 L5.59999983,31.2000012 C5.59999983,32.9664013 7.03359988,34.4000001 8.79999995,34.4000001 L31.2000008,34.4000001 C32.9664009,34.4000001 34.400001,32.9664013 34.400001,31.2000012 L34.400001,8.80000035 C34.400001,7.03360028 32.9664009,5.60000022 31.2000008,5.60000022 L31.2000008,5.60000022 Z" />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        ),
        imageText: 'practice exams',
        headerText: 'Kaplan practice exams',
        description: (
            <span>
                Included with every subscription, Kaplan&reg; practice exams (a $100 value) are a great way to test your knowledge and prepare you for the final
                exam.
            </span>
        ),
        learnMoreLink: '/features/practice-exams'
    },
    {
        image: (
            <svg width="34px" height="34px" viewBox="0 0 34 34" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g transform="translate(-1011.000000, -1363.000000)" fill="#FFBC00">
                        <g transform="translate(304.000000, 1360.000000)">
                            <g transform="translate(704.000000, 0.000000)">
                                <g>
                                    <path d="M20,3.33333333 C10.7952542,3.33333333 3.33333333,10.7952542 3.33333333,20 C3.33333333,29.2047458 10.7952542,36.6666667 20,36.6666667 C29.2047458,36.6666667 36.6666667,29.2047458 36.6666667,20 C36.66495,18.0713291 36.3207159,16.1582905 35.65,14.35 L32.9833333,17.0166667 C33.2156507,17.994111 33.3331118,18.9953266 33.3333333,20 C33.3333333,27.3637967 27.3637967,33.3333333 20,33.3333333 C12.6362033,33.3333333 6.66666667,27.3637967 6.66666667,20 C6.66666667,12.6362033 12.6362033,6.66666667 20,6.66666667 C21.0046734,6.66688823 22.005889,6.78434928 22.9833333,7.01666667 L25.6666667,4.33333333 C23.8491545,3.67730179 21.9322772,3.33902932 20,3.33333333 L20,3.33333333 Z M31.6666667,3.33333333 L25,10 L25,12.5 L20.75,16.75 C20.5062679,16.6812485 20.2528792,16.6530942 20,16.6666667 C18.1590508,16.6666667 16.6666667,18.1590508 16.6666667,20 C16.6666667,21.8409492 18.1590508,23.3333333 20,23.3333333 C21.8409492,23.3333333 23.3333333,21.8409492 23.3333333,20 C23.3469058,19.7471208 23.3187515,19.4937321 23.25,19.25 L27.5,15 L30,15 L36.6666667,8.33333333 L31.6666667,8.33333333 L31.6666667,3.33333333 L31.6666667,3.33333333 Z M20,10 C14.4771525,10 10,14.4771525 10,20 C10,25.5228475 14.4771525,30 20,30 C25.5228475,30 30,25.5228475 30,20 L26.6666667,20 C26.6666667,23.6818983 23.6818983,26.6666667 20,26.6666667 C16.3181017,26.6666667 13.3333333,23.6818983 13.3333333,20 C13.3333333,16.3181017 16.3181017,13.3333333 20,13.3333333 L20,10 Z" />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        ),
        imageText: 'accountability coaching',
        headerText: 'Accountability coaching',
        description: (
            <span>Need help with a training plan or schedule? Talk to one of our coaches. They can help guide you and answer all your questions.</span>
        ),
        learnMoreLink: '/accountability-coaching'
    },
    {
        image: (
            <svg width="30px" height="34px" viewBox="0 0 30 34" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g transform="translate(-309.000000, -1587.000000)" fill="#FFBC00">
                        <g transform="translate(304.000000, 1360.000000)">
                            <g transform="translate(0.000000, 224.000000)">
                                <g>
                                    <path d="M35,36.6666667 L28.3333333,36.6666667 L28.3333333,13.3333333 L35,13.3333333 L35,36.6666667 L35,36.6666667 Z M23.3333333,36.6666667 L16.6666667,36.6666667 L16.6666667,3.33333333 L23.3333333,3.33333333 L23.3333333,36.6666667 L23.3333333,36.6666667 Z M5,36.6666667 L5,23.3333333 L11.6666667,23.3333333 L11.6666667,36.6666667 L5,36.6666667 Z" />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        ),
        imageText: 'usage reporting',
        headerText: 'Usage reporting',
        description: (
            <span>
                See how you stack up on the leaderboard and track your progress with automated reports for individuals and advanced reporting for team admins.
            </span>
        )
    },
    {
        image: (
            <svg width="34px" height="25px" viewBox="0 0 34 25" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g transform="translate(-659.000000, -1529.000000)" fill="#FFBC00">
                        <g transform="translate(656.000000, 1521.000000)">
                            <path d="M14.4166667,15.8333333 L10.25,15.8333333 L10.25,11.6666667 L7.5,11.6666667 L7.5,15.8333333 L3.33333333,15.8333333 L3.33333333,18.5833333 L7.5,18.5833333 L7.5,22.75 L10.25,22.75 L10.25,18.5833333 L14.4166667,18.5833333 L14.4166667,15.8333333 Z M28.3333333,16.6666667 C30.6666667,16.6666667 32.5,14.8333333 32.5,12.5 C32.5,10.1666667 30.6666667,8.33333333 28.3333333,8.33333333 C27.9166667,8.33333333 27.5,8.41666667 27.0833333,8.5 C27.9166667,9.66666667 28.3333333,11 28.3333333,12.5 C28.3333333,14 27.8333333,15.3333333 27.0833333,16.5 C27.4166667,16.5833333 27.9166667,16.6666667 28.3333333,16.6666667 Z M21.4166667,16.6666667 C23.75,16.6666667 25.5833333,14.8333333 25.5833333,12.5 C25.5833333,10.1666667 23.75,8.33333333 21.4166667,8.33333333 C19.0833333,8.33333333 17.25,10.1666667 17.25,12.5 C17.25,14.75 19.0833333,16.6666667 21.4166667,16.6666667 Z M30.5833333,19.6666667 C31.75,20.6666667 32.5,21.9166667 32.5,23.5833333 L32.5,33 L36.6666667,33 L36.6666667,23.5833333 C36.6666667,21.4166667 33.3333333,20.0833333 30.5833333,19.6666667 Z M21.4166667,19.4166667 C18.6666667,19.4166667 13.0833333,20.8333333 13.0833333,23.5833333 L13.0833333,33 L29.75,33 L29.75,23.5833333 C29.75,20.8333333 24.1666667,19.4166667 21.4166667,19.4166667 Z" />
                        </g>
                    </g>
                </g>
            </svg>
        ),
        imageText: 'learner management',
        headerText: 'Learner management',
        learnMoreLink: '/features/learner-management',
        description: <span>Organize your team into custom groups, assign training based on specific learning needs, and easily track their progress.</span>
    }
];
