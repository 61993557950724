import styled from 'styled-components';
import { LearnerHeroInfo } from './learner-heros';

type DefaultHeroProps = {
    learner: LearnerHeroInfo;
};

export const LearnerSection = styled.section<DefaultHeroProps>`
    position: relative;
    display: grid;
    grid-gap: 0;
    grid-template-areas:
        '. cta . .'
        '. . caption .'
        'curve curve curve curve';
    padding: 2rem;
    background-color: #000;
    background-image: url(${(props) => `${props.learner.imageDesktop}`});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top right;
    overflow: hidden;
    margin-bottom: 5rem;

    @media screen and (max-width: ${(props) => props.theme.desktop930}) {
        background-image: url(${(props) => props.learner.imageTablet});
    }

    @media screen and (max-width: ${(props) => props.theme.phone560}) {
        grid-gap: 3rem;
        grid-template-columns: 1fr;
        grid-template-areas:
            'cta'
            'caption'
            'curve';
        padding: 2rem;
        background-size: 100%;
        background-image: url(${(props) => props.learner.imageMobile});
    }

    @media screen and (max-width: ${(props) => props.theme.phone}) {
        margin-bottom: 8rem;
    }
`;

export const TrainingCTA = styled.div`
    grid-area: cta;
    padding-bottom: 50%;
    max-width: 35rem;
    margin-top: 5rem;
    .teams {
        text-shadow: ${(props) => props.theme.textShadow};
    }
    @media screen and (max-width: ${(props) => props.theme.phone560}) {
        margin-top: 100%;
        max-width: 100%;
        padding: 0 3.5rem;
        justify-self: center;
        text-align: center;
    }

    h1 {
        color: ${(props) => props.theme.white};
        line-height: 0.7em;
        margin-top: 0;
        margin-bottom: 1rem;

        .lead-copy {
            font-weight: 600;
            font-size: 3.7rem;
            line-height: 3.7rem;
        }
    }

    p {
        color: ${(props) => props.theme.white};
    }

    a {
        display: block;
        margin: 1.5rem 0;
        pointer-events: auto;
        color: ${(props) => props.theme.white};
        text-decoration: underline;
        font-size: 2rem;
        line-height: 2.1rem;
    }

    button {
        pointer-events: auto;
    }

    svg {
        position: relative;
        top: 0.5rem;
        max-width: 2rem;
        max-height: 2rem;
    }
`;

export const ImageCaption = styled.div`
    grid-area: caption;
    padding-bottom: 10rem;
    justify-self: end;
    @media screen and (max-width: ${(props) => props.theme.desktop930}) {
        padding-bottom: 10rem;
    }
    @media screen and (max-width: ${(props) => props.theme.phone560}) {
        justify-self: center;
        text-align: center;
        padding-bottom: 6rem;
    }
    @media screen and (max-width: ${(props) => props.theme.phone}) {
        padding-bottom: 1rem;
    }
`;

export const BottomCurve = styled.div`
    border-radius: 100% 100% 0 0 / 30rem;
    background-color: #fff;
    grid-area: curve;
    width: 140%;
    height: 30rem;
    position: absolute;
    top: -5rem;
    left: -20%;
    @media screen and (max-width: ${(props) => props.theme.desktop930}) {
        width: 200%;
        left: -50%;
    }
    @media screen and (max-width: ${(props) => props.theme.phone}) {
        display: none;
    }
`;

export const LearnerNavStyles = styled.div`
    font-weight: 600;
    line-height: 2rem;
    h2 {
        margin-top: 0;
        color: ${(props) => props.theme.white};
        font-size: 2.4rem;
        margin: 1rem 0 0.5rem;
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.9);

        strong {
            font-weight: 900;
        }
    }

    h3 {
        color: ${(props) => props.theme.white};
        font-size: 1.8rem;
        margin: 0 0 0.5rem;
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.9);

        .link-arrow {
            top: 0.6rem;
            position: relative;
        }
    }

    a {
        color: ${(props) => props.theme.white};
        text-decoration: underline;
        display: flex;
        align-items: center;
        margin: 0;
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.9);
        pointer-events: auto;
        font-size: ${(props) => props.theme.largeFont};
        @media screen and (max-width: ${(props) => props.theme.phone560}) {
            justify-content: center;
            text-align: center;
        }
    }
`;
