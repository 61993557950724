import React, { FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import { UrlObject } from 'url';

interface BaseLinkType {
    ssrLink?: boolean;
    href: string;
    as?: string | UrlObject;
    attributes?: object;
    className?: string;
    newWindow?: boolean;
}

export const BaseLinkType: FunctionComponent<BaseLinkType> = ({
    ssrLink = false,
    href = '',
    as,
    className,
    children,
    newWindow = false,
    ...attributes
}) => {
    const rel = newWindow ? 'noopener noreferer' : undefined;
    const target = newWindow ? '_blank' : undefined;
    return !ssrLink ? (
        <a className={className} href={href} {...attributes} target={target} rel={rel}>
            {children}
        </a>
    ) : (
        <a href={href}>
            <a className={className} {...attributes}>
                {children}
            </a>
        </a>
    );
};

BaseLinkType.propTypes = {
    ssrLink: PropTypes.bool,
    href: PropTypes.string.isRequired,
    as: PropTypes.string,
    className: PropTypes.string,
};
