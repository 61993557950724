import styled from 'styled-components';

export const CompetitiveStyles = styled.section`
    max-width: 110rem;
    margin: -4rem auto 5rem;
    padding: 0 3.8rem;
    text-align: center;
    z-index: 4;
    position: relative;

    div {
        display: flex;
    }

    h2 {
        font-size: 3.2rem;
        font-weight: 600;
        line-height: 4rem;
        margin: 0 auto 1.5rem;

        @media only screen and (max-width: ${(props) => props.theme.phone560}) {
            max-width: 39rem;
        }

        @media only screen and (max-width: ${(props) => props.theme.xsphone}) {
            font-size: 2.6rem;
        }
    }

    p {
        font-size: 1.6rem !important;
        line-height: 2.3rem;

        a {
            font-size: 1.6rem;
        }
    }

    .double-cursive-lines {
        margin-top: 0;

        @media only screen and (max-width: ${(props) => props.theme.xsphone}) {
            max-width: 80%;
        }
    }
`;

export const FeaturesStyle = styled.article`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 0;
    margin: 5rem auto;
    text-align: center;
    grid-gap: 2rem 6rem;

    aside {
        max-width: 35rem;
        padding: 4rem 0;
        text-align: left;

        a {
            font-size: 2.6rem;
            line-height: 3rem;

            img {
                margin-left: 0.3rem;
                height: 2.1rem;
                width: 2.1rem;
            }
        }

        @media only screen and (max-width: ${(props) => props.theme.tablet}) {
            padding-bottom: 0;
        }
    }

    @media only screen and (max-width: ${(props) => props.theme.desktop1200}) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media only screen and (max-width: ${(props) => props.theme.tablet}) {
        grid-template-columns: auto;

        aside {
            white-space: nowrap;
        }
    }

    @media only screen and (max-width: ${(props) => props.theme.phone560}) {
        margin-top: 1.5rem;
        grid-template-columns: 1fr;
    }

    @media only screen and (max-width: ${(props) => props.theme.phone}) {
        aside {
            padding: 1rem 0;

            a {
                font-size: 2.2rem;
            }
        }
    }
`;
