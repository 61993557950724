import { createGlobalStyle } from 'styled-components';
import textStyles from './text-styles';
import tableStyles from './table-styles';

export const GlobalStyle = createGlobalStyle`
    html,
    *,
    body {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    html {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 62.5%;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
    *, *:before, *:after {
        -webkit-box-sizing: inherit;
        -moz-box-sizing: inherit;
        box-sizing: inherit;
    }

    body {
        overflow-x: hidden;
        padding: 0;
        margin: 0;
        font-size: ${(props) => props.theme.standardFont};
        font-family: ${(props) => props.theme.fontFamily};
        background-color: ${(props) => props.theme.offWhite};
        line-height: 1.5;
    }

    button,
    input,
    textarea,
    select {
        outline: 0;
    }

    ${textStyles}
    ${tableStyles}

    #__next {
        max-width: 164.8rem;
        padding-top: 6.5rem;
        margin: 0 auto;
        position: relative;
        background-color: white;
        box-shadow: 0 0 16px rgba(23, 39, 51, 0.1);
    }
`;
