import styled from 'styled-components';

export const SiteLayoutWrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 6.5rem);
    position: relative;
    main {
        flex: 1;
    }
`;
