import styled from 'styled-components';

export const GetStarted = styled.aside`
    text-align: center;

    h2 {
        margin-bottom: 1.6rem;
        font-weight: 600;

        @media screen and (max-width: ${(props) => props.theme.phone560}) {
            font-size: 2.6rem;
        }
    }

    p {
        margin-top: 4rem;
        font-size: 1.8rem;
        button {
            font-size: 1.8rem;
            color: ${(props) => props.theme.blue};
            line-height: 1.5;
            font-weight: 200;
            text-decoration: underline;
        }
    }

    a {
        color: ${(props) => props.theme.primary};
        border-radius: 3.2rem;
        padding: 1.3rem 5rem;
        font-size: 1.8rem;
        font-weight: 600;
        margin: 2.4rem auto;
        background-color: ${(props) => props.theme.gold};
        text-align: center;
        display: inline-block;
        transition: all 0.3s;
        &:hover {
            filter: brightness(115%);
        }
        &:active {
            filter: brightness(85%);
        }
    }
`;

export const GetStartedSection = styled.section`
    position: relative;
    padding: 8rem 4rem;
    overflow: hidden;

    .hotspots {
        position: absolute;
        width: 20rem;
        height: 20rem;

        &.spots1 {
            top: -12rem;
            left: 10%;
        }
        &.spots2 {
            right: 5%;
            top: -15rem;
        }
        &.spots3 {
            bottom: -15rem;
            left: -5rem;
        }
        &.spots4 {
            width: 45rem;
            height: 45rem;
            right: -5rem;
            bottom: -35rem;
        }

        @media screen and (max-width: ${(props) => props.theme.desktop990}) {
            &.spots1 {
                left: -2rem;
                top: -12rem;
            }

            &.spots2 {
                top: unset;
                right: -4rem;
                bottom: -12rem;
            }

            &.spots3,
            &.spots4 {
                display: none;
            }
        }

        @media screen and (max-width: ${(props) => props.theme.tablet}) {
            &.spots1 {
                top: -14rem;
            }
            &.spots2 {
                bottom: -14rem;
            }
        }
    }

    a {
        margin-top: 0;
    }
`;
