import styled from 'styled-components';

export const OurLibraryStyle = styled.section`
    text-align: center;
    margin: 0 auto;
    background-color: ${(props) => props.theme.primary};
    padding-top: 5.6rem;
    padding-bottom: 5.6rem;

    h2 {
        max-width: 57rem;
        padding: 0 3.8rem;
        font-size: 3.2rem;
        font-weight: 600;
        margin: 0 auto 4rem;
        color: ${(props) => props.theme.white};

        @media only screen and (max-width: ${(props) => props.theme.phone560}) {
            font-size: 2.6rem;
        }
    }

    a {
        font-size: 3.2rem;
        cursor: pointer;
        font-weight: ${(props) => props.theme.regular};
        color: ${(props) => props.theme.mediumGrey};
    }

    a.full-library {
        font-size: 1.6rem;
        font-weight: ${(props) => props.theme.regular};
        color: ${(props) => props.theme.white};
        max-width: 13rem;
        text-align: left;
        line-height: 2rem;
        display: inline-flex;
        align-items: flex-end;

        svg {
            width: 2.1rem;
            height: 2.1rem;
        }
    }

    .library-vendors,
    .library-certificates {
        width: 110.8rem;
        height: 5rem;
        margin: 2rem auto;
        text-align: center;
        a {
            margin: 1rem 0;
        }
    }
    .library-vendors {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        margin-bottom: 4rem;
    }

    .library-certificates {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .library-img {
            top: 0.6rem;
            position: relative;
        }
    }

    @media only screen and (max-width: ${(props) => props.theme.desktop1200}) {
        .library-vendors,
        .library-certificates {
            width: 100rem;
        }
    }

    @media only screen and (max-width: ${(props) => props.theme.desktop990}) {
        .library-vendors,
        .library-certificates {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 3rem;
            width: 50rem;
            height: auto;
        }

        a.full-library {
            max-width: 100%;
            grid-column: span 3;
            margin: 0 auto;
            text-align: center;
            white-space: nowrap;
            align-items: normal;

            span {
                margin-left: 0.5rem;
            }
        }

        .library-certificates {
            margin-bottom: 0;

            .library-img {
                top: 0.3rem;
            }
        }
    }

    @media only screen and (max-width: ${(props) => props.theme.phone560}) {
        margin: 0 auto;
        padding: 4rem 0;

        .library-vendors,
        .library-certificates {
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 2rem;
            width: 30rem;

            a.full-library {
                grid-column: span 2;
                margin-top: 2rem;

                img {
                    width: 2.1rem;
                }

                span {
                    width: unset;
                }
            }
        }
    }

    @media only screen and (max-width: ${(props) => props.theme.xsphone}) {
        .library-vendors,
        .library-certificates {
            grid-gap: 1rem;
            width: 24rem;
        }
    }
`;
