import React, { FunctionComponent } from 'react';
import { features } from './features';
import Feature from './Feature';
import { CompetitiveStyles, FeaturesStyle } from './competitive-styles';
import { LinkAsButton } from '../../common/Buttons';

const Competitive: FunctionComponent = () => (
    <CompetitiveStyles>
        <h2>Stay competitive and get the job done.</h2>
        <p className="lead-copy">All features included with every subscription.</p>
        <svg
            className="double-cursive-lines"
            height="15"
            viewBox="0 0 246 15"
            width="246"
            xmlns="http://www.w3.org/2000/svg">
            <g fill="#ffbc00" fillRule="evenodd">
                <path d="m1.42913801 11.3253706c78.83199999-9.35000004 158.47799999-10.82900004 237.60299999-4.41400004 1.924.156 1.914-2.845 0-3-79.125-6.415-158.771-4.936-237.60299999 4.414-1.894.225-1.917 3.22700004 0 3.00000004" />
                <path d="m61.910638 14.5416706c60.402-6.07900004 121.129-9.05800004 181.836-7.55900004 1.932.048 1.931-2.952 0-3-60.707-1.499-121.434 1.48-181.836 7.55900004-1.903.191-1.922 3.193 0 3" />
            </g>
        </svg>
        <div>
            <FeaturesStyle>
                {features.map(feature => (
                    <Feature
                        key={feature.headerText}
                        image={feature.image}
                        imageText={feature.imageText}
                        headerText={feature.headerText}
                        description={feature.description}
                        learnMoreLink={feature.learnMoreLink || ''}
                    />
                ))}
            </FeaturesStyle>
        </div>
        <LinkAsButton loggedin secondary href="/features">
            View all features
        </LinkAsButton>
    </CompetitiveStyles>
);

export default Competitive;
