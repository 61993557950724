import React, { createContext, useState, FunctionComponent } from 'react';

type UserAuthState = {
    isLoggedIn: boolean;
    loginInProgress: boolean;
    loginFailedError?: string | null;
    user?: object;
};

const defaultAuth: UserAuthState = { isLoggedIn: false, loginInProgress: false, loginFailedError: null, user: {} };

export const UserContext = createContext<UserAuthState>(defaultAuth);
export const UserContextProvider: FunctionComponent = ({ children }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [authentication, setAuthentication] = useState<UserAuthState>(defaultAuth);

    return <UserContext.Provider value={authentication}>{children}</UserContext.Provider>;
};
